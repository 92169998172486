import { useEffect, useState, useRef } from 'react';
import Styles from './App.module.css'
import FStyles from './sendforum.module.css'
import Bubble from './Bubble';
import phoneIm from './Media/cover.png'
import fb from './Media/fb.png'
import ig from './Media/ig.png'
import sc from './Media/sc.png'
import tw from './Media/tw.png'
import yu from './Media/yu.png'
import download from './Media/Download.png'

import { io } from 'socket.io-client';

import axios from 'axios';


function App() {



  const [userAmount, setuserAmount] = useState()
  const [convsAmount, setconvsAmount] = useState()
  const [messagesAmount, setmessagesAmount] = useState()

  
  const [homeView, sethomeView] = useState(true)
  const [contactView, setcontactView] = useState(false)
  
  
  const [sentform, setsentform] = useState(false)
  const fname = useRef()
  const femail = useRef()
  const fid = useRef()
  const fmes = useRef()

  document.title = 'TalkStudio';


console.log("Env:", process.env.REACT_APP_GETD)
  useEffect(() => {
    
    var call = {
      method: 'get',
      url: process.env.REACT_APP_GETD,
    };
    
    axios(call)
    .then((x) =>  {
      console.log(x.data);

      setuserAmount(x.data.userAmount)
      setconvsAmount(x.data.Conversations)
      setmessagesAmount(x.data.Messages)
    })
    .catch((x) =>  {
      console.log(x);
    })
    


  }, [])
/*
  useEffect(() => {
    console.log("SocketL", socket)
    
    if(socket === null || socket === undefined){
      console.log("Attempting to connect to server")
      setsocket(io("https://talkk.glitch.me"))
    }else {
      console.log("Connected to server")
      socket.emit('ImOnline', "sd")

      socket.on("webstat", (h) => {
        console.log(h)
      })
    }
    
  }, [socket])
*/



  const sendForm = event => {

  event.preventDefault();

  var call = {
    method: 'post',
    url: process.env.REACT_APP_SEND,
    params: {
      "fname" : fname.current.value,
      "femail" : femail.current.value,
      "fid" : fid.current.value,
      "fmes" : fmes.current.value,
      'responded': false 
    }
    
  };
  
  console.log("Forum sent")
  setsentform(true)

  axios(call)
}
 

function openInNewTab(url) {
  var win = window.open(url, '_blank');
  win.focus();
}

  return (
    <div className={Styles.root}>

      <div className={Styles.header}>
        <div className={Styles.logocont}>  
          <p className={Styles.t0}>TalkStudio™</p>
        </div>
        <div className={Styles.MenuButtons}>
          <p className={homeView ? Styles.MenuTextSelected : Styles.MenuTextDescleted} onClick={() => {sethomeView(true); setcontactView(false)}}> Home</p>
          <p className={contactView ?  Styles.MenuTextSelected: Styles.MenuTextDescleted} onClick={() => {sethomeView(false); setcontactView(true)}}> Contact Us</p>
        </div>
        <div className={Styles.menublank}>
          <p>.</p>
        </div>
      </div>

      
      <view className={contactView ? Styles.PannelLarge : Styles.hide}>
        <view className={sentform ? Styles.hide : Styles.show}>
          <form onSubmit={sendForm}> 
              <div className={Styles.FormBoxesContainer}>
                <input required={true} ref={fname} className={FStyles.input} maxLength={30} placeholder={"Name"} autoComplete={'given-name'}/>
                <input required={true} ref={femail} className={FStyles.input} maxLength={30} type={'email'} placeholder={"Email"} autoComplete={'email'} />
                <input required={false} ref={fid} className={FStyles.input} maxLength={30} placeholder={"Talk ID (Optional)"}/>
                <textarea required={true} ref={fmes} className={FStyles.inputLarge} maxLength={3000} placeholder={"Message"}/>
                <input className={FStyles.submit} type="submit" placeholder={'Send 😊'}/>
              </div>
          </form>
        </view>

        <view className={sentform ? Styles.show : Styles.hide}>
          <p>Thank you for reaching out, we'll get in touch shortly 😁</p>
        </view>
        
      </view>


      <view className={homeView ? Styles.show : Styles.hide}>
      
      <div className={Styles.PannelLarge}>
        <div>
          <p className={Styles.quote}>“Communication is at the very core of our society, its what makes us human”</p>
          <p className={Styles.quote}> - J.K </p>
          </div>
          <img alt={"phoneimage"} className={Styles.iphoneim} src={phoneIm}></img>
      </div>


  
      <div className={Styles.PannelSplit}>

        <div className={Styles.PannelSplit_Small}>
        <div className={Styles.PannelSplit_InnerPad}>
          <div className={Styles.ZeroB}>
          <p className={Styles.title}>0</p>
          <p style={{fontSize: '4vmin'}}>bytes</p>
          </div>
          <p>Talk! is fully anonymouse, no personal data is requtesed or scretly taking within the code, we does this by UUID tech to identifiye users  which randomly genreates ID's. </p>
          </div>
        </div>

        <div className={Styles.PannelSplit_Bubbles}> 
        <div className={Styles.PannelSplit_InnerPadBubble}>
              <Bubble text="Feeling a bit down today but have no idea why? Nothing's really changed in my life recently but a few days ago I've started feeling a little different than normal." Color="ye"/>
              <Bubble text="New music please. Bored of my music need a new artist to get into" Color="pi"/>
              <Bubble text="So my friends keep trying to pressure me into smoking weed and I don't really want to, how do I avoid the topic ?" Color="bl"/>
              <Bubble text='Recently been struggling fitting truggling fittin how do you keep your identity while also trying to fit in to a group?' Color="ye"/>
              <Bubble text='Whats your life ambition? I havent really got any and not sure if thats a bad thing?' Color="re"/>
              <Bubble text="Don't you find it weird that every week there's a new news story that completely takes over the internet and the last story gets forgotten about?" Color="ye"/>
              <Bubble text='Why is it that nature brings such peace?🌲' Color="ge"/>
              <Bubble text="Never thought color could trigger certin emotions, htf does that work?" Color="bl"/>
              <Bubble text="Do you think there's a correlation between drugs and happiness?" Color="ge"/>
              <Bubble text="Whats a good podcast to watch?" Color="ye"/>
              </div>
        </div>

        <div className={Styles.PannelSplit_SmallHide}>
        <div className={Styles.PannelSplit_InnerPad}>
              <p className={Styles.TitleSmall}>How it works</p>
              <p>The concept of talk is very simple, download the app, no sign up is required. Post a conversation you would like to talk about which will be see on everyones feed. Someone else picks up the convo starting a 1:1 conversations. Thats it! Heres some example convos.</p>
              </div>
        </div>

      </div>


      <div className={Styles.PannelSplit_SmallShow}>
        <div className={Styles.PannelSplit_InnerPad}>
              <p className={Styles.title}>How it works</p>
              <p>The concept of talk is very simple, download the app, no sign up is required. Post a conversation you would like to talk about which will be see on everyones feed. Someone else picks up the convo starting a 1:1 conversations. Thats it! Heres some example convos.</p>
              </div>
        </div>

      <div className={Styles.PannelLarge}>
        <div className={Styles.DownloadPannel}>
          <p className={Styles.downloadText}>Download Now</p>
          <img atl={'down'} className={Styles.downloadim} src={download}/>
          </div>
          <img alt={"phoneimage"} className={Styles.iphoneim} src={phoneIm}></img>
      </div>


    <div className={Styles.PannelSplit}>

      <div className={Styles.PannelSplit_Small}>
        <div className={Styles.PannelSplit_InnerPad}>
          <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start',alignItems: 'flex-start' ,flex: 3}}>
            <div>
              <div>
                <p className={Styles.title}>{messagesAmount}</p>
                <p style={{margin: '0px', padding: '0px'}}>Messages</p>
              </div>   
            </div>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', width: '100%'}}>
              <div>
                <p className={Styles.title}>{convsAmount}</p>
                <p style={{margin: '0px', padding: '0px'}}>Conversations</p>
              </div>
              <div style={{marginLeft: '14vw'}}>
                <p className={Styles.title}>{userAmount}</p>
                <p style={{margin: '0px', padding: '0px'}}>Users</p> 
              </div>
            </div>
          </div>
        </div>
      </div>

<div className={Styles.showSpacing}>
  <p style={{color: 'transparent'}}>...</p>
</div>



      <div className={Styles.PannelSplit_SmallHide}>
        <div className={Styles.PannelSplit_InnerPad}>
        <div className={Styles.socialPannel}>
          <img onClick={() => openInNewTab('https://twitter.com/TalkStudio_UK')}  className={Styles.s1} src={tw}></img>
          <img onClick={() => openInNewTab('https://www.instagram.com/talkstudiouk/?hl=en')}  className={Styles.s1} src={ig}></img>
          </div>
          <div className={Styles.socialPannel}>
            <img className={Styles.s1} src={sc}></img>
            <img className={Styles.s1} src={yu}></img>
          </div>
        </div>
      </div>
    </div>


    <div className={Styles.PannelSplit_SmallShow}>
        <div className={Styles.PannelSplit_InnerPad}>
        <img onClick={() => openInNewTab('https://twitter.com/TalkStudio_UK')}  className={Styles.s1} src={tw}></img>
          <div className={Styles.socialPannel}>
          <img onClick={() => openInNewTab('https://www.instagram.com/talkstudiouk/?hl=en')}  className={Styles.s1} src={ig}></img>
            <img className={Styles.s1} src={sc}></img>
            <img className={Styles.s1} src={yu}></img>
          </div>
        </div>
      </div>


    <div>
      <p style={{fontSize: '1.5vmin', color: '#0000007d', marginBottom: '4vh'}}>Privacy Policy    Terms & Conditions </p>
    </div>
  </view>
</div>)

}

export default App;
