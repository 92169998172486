import { useEffect, useState } from 'react';
import Styles from './Bubble.module.css'
import ColorSet from './col';


const Bubble = ({text, Color}) =>{

    const [vis, setvis] = useState(false)

    const [over, setover] = useState("")
    useEffect(() => {
    if(text.length >= 75) {
        setover("...")
    }
    }, [])

    return(
    <div className={Styles.root} style={{backgroundColor: ColorSet(Color)}} onClick={() => setvis(!vis)}>
        <p className={Styles.text} >{text.slice(0,75)}{over}</p>        

        <div className={vis ? Styles.overShow : Styles.overhide}>
            <div className={Styles.rootov} style={{backgroundColor: ColorSet(Color)}}>
                <p className={Styles.textov} >{text}</p>        
            </div>
        </div>
    </div>
    )

}

export default Bubble;