

const ColorSet = (code) => {

    if(code === 'ye') return "#f4e04e"
    if(code === 'ge') return "#75db7f"
    if(code === 'bl') return "#8d94e8"
    if(code === 'pi') return "#e277de"
    if(code === 're') return "#df7187"
}

export default ColorSet;